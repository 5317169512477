






















































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { alertContainer } from "@/containers/AlertContainer"
import {
  Meeting,
  MeetingStructure,
  Agenda,
  MeetingContent,
} from "@/models/Meeting"
import User from "@/models/User"
import DateTime from "@/models/DateTime"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import { Props } from "./types"
import PickDateTimeRange from "@/components/pickDateTime/PickDateTimeRange.vue"
import ExternalAttendeesAdd from "@/components/ExternalAttendeesAdd.vue"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"
import AgendaInput from "@/views/NewMeetingCreation/AgendaInput.vue"
import FinalConfirmation from "@/views/NewMeetingCreation/FinalConfirmation.vue"

interface State {
  isLoading: boolean
  isFormValid1: boolean
  isFormValid2: boolean
  members: Array<User>
  meetingStructure: MeetingStructure
  meeting: Meeting
  errorMsg: string
}

const propsOptions = {
  id: {
    type: String,
    default: "",
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedMessage,
      TextField,
      DefaultButton,
      WithBackPageLayout,
      PickDateTimeRange,
      ExternalAttendeesAdd,
      UsersAutoComplete,
      AgendaInput,
      FinalConfirmation,
    },
    setup(props) {
      const {
        state: meetingState,
        getAndSetMeetingDetails,
        createMeetingAsync,
      } = meetingContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()
      const { getContext, getMembers } = teamsContextContainer.useContainer()
      const step = ref(1)
      const meetingStructureBase = new MeetingStructure(
        meetingState.meetings.value?.find(v => v.id === props.id) ??
          ({} as MeetingStructure)
      )
      const state = reactive<State>({
        isLoading: false,
        isFormValid1: false,
        isFormValid2: false,
        members: [],
        meetingStructure: meetingStructureBase,
        meeting: new Meeting({
          subject: meetingStructureBase.name,
          location: meetingStructureBase.location,
          isTeams: true,
          agendas: [new Agenda(), new Agenda()],
        } as Meeting),
        errorMsg: "",
      })

      const { showSuccessMessage } = alertContainer.useContainer()
      const setCurrentUser = () => {
        if (state.meetingStructure && state.members) {
          const target = state.members.filter(m =>
            state.meetingStructure?.userIds.includes(m.userId ?? "")
          )
          state.meeting.users.slice(0, state.meeting.users.length)
          state.meeting.users.push(...target)
          state.meeting.users.push(...state.meetingStructure.users)
        }
      }
      const externalUsers = computed({
        get: () => state.meeting.users.filter(u => !u.id),
        set: newValue => {
          state.meeting.users = state.meeting.users
            .filter(u => u.id)
            .concat(newValue)
        },
      })

      const meetingContent = computed({
        get: () =>
          new MeetingContent({
            purpose: state.meeting.purpose,
            goal: state.meeting.goal,
            agendas: state.meeting.agendas,
          }),
        set: newValue => {
          state.meeting.purpose = newValue.purpose
          state.meeting.goal = newValue.goal
          state.meeting.agendas = newValue.agendas
        },
      })

      const onFormValidityChanged1 = (isValid: boolean) => {
        if (isValid) state.isFormValid1 = true
      }

      const onFormValidityChanged2 = (isValid: boolean) => {
        state.isFormValid2 = isValid
      }

      const isValid1 = computed<boolean>(
        () =>
          state.isFormValid1 &&
          state.meeting.users.filter(u => u.userId).length > 0
      )

      watch(
        () => meetingState.isLoadingMeetings.value,
        newValue => {
          if (!newValue) {
            state.meetingStructure = new MeetingStructure(
              meetingState.meetings.value?.find(v => v.id === props.id) ??
                ({} as MeetingStructure)
            )
          }
        }
      )

      watch(
        () => state.meetingStructure,
        newValue => {
          if (newValue) {
            state.meeting = new Meeting({
              subject: newValue.name,
              location: newValue.location,
              isTeams: true,
            } as Meeting)
          }
        }
      )

      watch(
        () => state.members,
        () => {
          setCurrentUser()
        }
      )

      onMounted(async () => {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        await getAndSetMeetingDetails(project.siteId, context.entityId)
        state.members = (await getMembers()).map(m => new User(m))
      })

      const createMeeting = async () => {
        state.isLoading = true
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        if (context.entityId && project.siteId) {
          state.meeting.agendas = state.meeting.agendas?.filter(a => a.agenda)
          await createMeetingAsync(
            project.siteId,
            context.entityId,
            state.meetingStructure.id,
            state.meeting,
            true
          )
          showSuccessMessage("会議を作成しました")
          goToRoute({
            name: RouteName.MeetingSeries,
            params: {
              id: state.meetingStructure.id,
            },
          })
          state.isLoading = false
        }
      }

      const attendeesCount = computed<number>(() => {
        const internalAttendees = state.meeting.users.filter(
          u => u.userId !== undefined
        )
        return internalAttendees.length
      })

      const setTime = (value: DateTime | null | undefined) => {
        if (value) return value.toDateTimeString()
        else return ""
      }

      const updateTime = (key: "startTime" | "endTime", newValue: string) => {
        if (state.meeting) state.meeting[key] = new DateTime(newValue)
      }

      return {
        state,
        step,
        attendeesCount,
        onFormValidityChanged1,
        onFormValidityChanged2,
        isValid1,
        createMeeting,
        setTime,
        updateTime,
        externalUsers,
        meetingContent,
      }
    },
  }
)
